.btn-outline-primary {
  color: white !important;
  background: transparent !important;
  border-color: #00316c !important;
}

.btn-primary {
  color: white !important;
  background: #00316c !important;
  border-color: #00316c !important;
}

.btn-secondary {
  color: white !important;
  background: #3a4047 !important;
  border-color: #3a4047 !important;
}

.btn-outline-secondary {
  color: white !important;
  background: transparent !important;
  border-color: #3a4047 !important;
}

.btn-outline-primary:hover {
  background: #00316c !important;
}

.btn.outline-secondary:hover {
  background: #3a4047 !important;
}

.top-buffer {
  padding-top: 120px;
}

.bottom-buffer {
  padding-bottom: 60px;
}
